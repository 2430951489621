<template>
  <div :class="`${prefixCls}-talent`" id="aochenTalent">
    <Separate title="研究成果" desc="R&D RESULT"></Separate>
    <div :class="`${prefixCls}-talent-content`">
        <p v-html="content"></p>
    </div>
  </div>
</template>

<script>
import Separate from '../../../components/Separate/index'
import { mapMutations } from 'vuex'
import { removeHTMLTag } from '../../../utils/util'
import { siteYfcxList } from '@/api'
export default {
  name: 'InnovativeTalents',
  data () {
    return {
      prefixCls: this.$prefix + '-press',
      centerList: [],
      centerMain: {},
      content: ''
    }
  },
  props: {
    centerData: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  created () {
    this.siteYfcxList()
  },
  methods: {
    ...mapMutations(['setData']),
    removeHTMLTag,
    siteYfcxList () {
      siteYfcxList({
        type: 3
      }).then(({ data }) => {
        this.content = data.data[0]?.content
      })
    },
    goList () {
      const { href } = this.$router.resolve({
        path: '/press/list',
        query: { type: '5' }
      })
      window.open(href, '_blank')
    },
    goDetail (item) {
      // 目标路由对象
      const { href } = this.$router.resolve({
        path: '/press/detail'
      })
      window.open(`${href}?id=${item.id}`, '_blank')
    }
  },
  components: {
    Separate
  }
}
</script>

<style lang="scss" scoped>
$prefixCls: $namespace + -press-talent;
.#{$prefixCls}{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: $content-width;
  ::v-deep &-content {
    word-spacing: 14px;
    text-align: left;
    span, p, font {
      font-family: arial !important;
      font-size: 16px !important;
      color: #191818 !important;
      line-height: 40px !important;
      font-weight: lighter !important;
      text-align: justify;
    }
  }
}
</style>
